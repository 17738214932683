// @flow
import * as React from 'react';
import DefaultLayout from '../layouts/default';
import { graphql } from 'gatsby';
import Container from '../components/grid/container';
import Page from '../partials/page';

type Props = {|
  data: {
    markdownRemark: {
      html: string,
      frontmatter: {
        title: string,
        feature?: {
          childImageSharp: {
            fixed: {
              src: string,
            },
          },
        },
      },
    },
  },
|};

const PageTemplate = ({ data: { markdownRemark: { html, frontmatter } } }: Props) => {
  const feature = frontmatter.feature ? frontmatter.feature.childImageSharp.fixed.src : undefined;
  return (
    <DefaultLayout slim>
      <Container withColumn>
        <Page title={frontmatter.title} featureImgSrc={feature}>
          <div dangerouslySetInnerHTML={{ __html: html }}/>
        </Page>
      </Container>
    </DefaultLayout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                feature {
                    childImageSharp {
                        fixed(width: 1500) {
                            src
                        }
                    }
                }
            }
        }
    }
`;
