// @flow
import * as React from 'react';

import './page.less';
import { Helmet } from 'react-helmet';

type Props = {|
  title: string,
  children: React.Node,

  // Optional
  featureImgSrc?: string,
|};

const Page = ({ title, children, featureImgSrc }: Props) => (
  <section className="page">
    <Helmet>
      {featureImgSrc && (
        <meta name="twitter:image" content={'https://platformpixels.com' + featureImgSrc}/>
      )}
      <meta name="twitter:title" content={title}/>
    </Helmet>
    <header className="page__header">
      <h1>{title}</h1>
    </header>
    <div className="page__content">
      {children}
    </div>
  </section>
);

export default Page;
